import loadable from "@loadable/component";
import { inject } from "mobx-react";
import type { Store } from "@inferno/renderer-shared-core";
import { Remote } from "../../components/remote/Remote.component";
import { Block_SitesItemBlock_Fragment, PublishRecordFragment } from "@ihr-radioedit/inferno-webapi";
import { getContentEyebrow } from "../../lib/content";
import { getEyebrow } from "../../services/Sites.utils";
import { getTopic, LANGUAGES } from "@ihr-radioedit/inferno-core";

const Eyebrow = loadable(() => import("../../components/Eyebrow.component"));

interface EyebrowLoaderInterface {
  store?: Store;
  content: PublishRecordFragment;
  block: Block_SitesItemBlock_Fragment;
}

export const EyebrowLoader = inject("store")(({ content, block, store }: EyebrowLoaderInterface) => {
  if (!store) {
    return null;
  }

  const hideEyebrow = !!block.tags?.includes("display-hints/content-hide-eyebrow");
  if (hideEyebrow) {
    return null;
  }

  const { site, microsite, getSdkOpts } = store;
  const locale = site.sections.general?.locale?.taxo?.name ?? LANGUAGES.English;
  const topic = getEyebrow(content.subscription);

  return (
    <Remote loader={() => getTopic(topic, locale, getSdkOpts())} cacheKey={`item-eyebrow-${content.ref_id}`}>
      {({ data }) => {
        const eyebrow = {
          topic,
          topic_name: data?.displayName || data?.name,
        };
        store.storeBlock({
          ...block,
          type: "item",
          eyebrow,
        });
        const { eyebrowUrl, topicName } = getContentEyebrow(site, microsite, content, eyebrow);
        if (topicName && eyebrowUrl) {
          return <Eyebrow topic_name={topicName} url={eyebrowUrl} />;
        }
        return null;
      }}
    </Remote>
  );
});

export default EyebrowLoader;
